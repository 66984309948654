import React, {useContext, useEffect} from 'react';
import PropTypes from 'prop-types';
import Numeral from 'numeral';
import styled from 'styled-components';
import PaymentControl from './fcc/PaymentControl';
import * as Yup from 'yup';
import { Card, Col, Form, Modal, Row } from 'react-bootstrap';
import { BottomButtonGroup, CurrencyInput } from '../common/components';
import { withSettings } from '../common/providers';
import { getCurrency } from '../common/helpers';
import { Formik, Form as FormikForm } from 'formik';
import './TipPrompt.scss';
import { ThemedButton } from '../common/Themed';
import {TimeoutContext} from '../common/providers/TimeoutProvider';

const EditPay = styled.span`
  color: ${props => props.theme.primaryColor};
  font-weight: normal;
  font-size: 1rem;
  &:hover {
    cursor: pointer;
    text-decoration: underline;
  }
`;

const TipContainer = styled.div`
  color: ${props => props.isselected ? props.theme.primaryColor : null} !important;
  border-color: ${props => props.isselected ? props.theme.primaryColor : null} !important;

  &:hover {
    border-color: ${props => props.theme.primaryColor};
  }
`;

const TipPrompt = ({
  settings,
  checkAmount,
  show,
  paymentType,
  qrMode,
  onCancel,
  onConfirm,
  shouldRenderPaymentControl,
}) => {
  const [showPaymentControl, setShowPaymentControl] = React.useState(false);
  const [editPay, setEditPay] = React.useState(false);
  const [tip, setTip] = React.useState({ index: 3, amount: 0, input: '$0' });
  const [authAmount, setAuthAmount] = React.useState(checkAmount);
  const formattedAuthAmount = getCurrency(authAmount).formattedValue;
  const total = authAmount + tip.amount;
  const { primaryColor } = settings;
  const tips = [.15, .18, .20, 0]
    .map(rate => ({
      name: rate * 100 > 0 ? (rate * 100) + '%' : 'No Tip',
      amount: +(authAmount * rate).toFixed(2),
    }));

  const AuthAmountSchema = Yup.object().shape({
    authAmount: Yup.string()
      .required('Must be a valid amount')
      .test('min', 'Must be a valid amount', val => Numeral(val).value() > 0)
      .test('max', 'Amount exceeds balance', val => Numeral(val).value() <= checkAmount)
  });

  const {
    pauseTimeoutTimer,
    resumeTimeoutTimer,
    resetTimeoutTimer,
  } = useContext(TimeoutContext);
  
  useEffect(() => {
    return () => {
      resumeTimeoutTimer();
    }
  }, []);

  const styles = {
    body: {
      paddingBottom: qrMode ? '1rem' : '80px',
    },
    input: {
      textAlign: 'center',
      outline: 'none',
      width: '100%',
      padding: '1.25rem 0',
    },
    card: {
      cursor: 'pointer',
    },
    selected: {
      cursor: 'pointer',
      border: `1px solid ${primaryColor}`,
    }
  };

  const buttons = [
    {
      name: 'Next',
      primary: true,
      onClick: () => onConfirm({ tip: tip.amount }),
    },
  ];

  const handleAuthAmountSubmit = (values) => {
    const { numericValue: amount } = getCurrency(values.authAmount);

    setAuthAmount(amount);
    setTip({
      ...tip,
      index: 3,
      amount: 0,
      input: '$0',
    });
    setEditPay(false);
  };

  const handlePay = (params) => {
    onConfirm({
      ...params,
      amount: authAmount,
      tip: tip.amount,
    });
  };

  const handleTipConfirm = () => {
    // Tip prompt may also display the payment control used to pay for the total
    if (shouldRenderPaymentControl) {
      displayPaymentControl();

      return;
    }
    
    onConfirm({
      amount: authAmount,
      tip: tip.amount,
    });
  };
  
  const displayPaymentControl = () => {
    // Idle timer does not detect key events within the payment iframe so we pause it
    resetTimeoutTimer();
    pauseTimeoutTimer();
    setShowPaymentControl(true);
  };

  const renderAuthAmount = () => (
    <Formik
      initialValues={{ authAmount: formattedAuthAmount }}
      validationSchema={AuthAmountSchema}
      enableReinitialize={true}
      onSubmit={handleAuthAmountSubmit}
    >
      {({ errors, handleChange, setFieldValue, isValid, values }) =>

        <Card className="m-1 shadow-sm">
          <Card.Body className="p-3">
            <FormikForm>

              <Form.Group>
                <Form.Label>
                  Amount
                  <span className="text-muted ml-1">
                    (Balance: {' '}
                    <strong>
                      {Numeral(checkAmount).format('$0,0.00')}
                    </strong>
                    )
                  </span>
                </Form.Label>
                <CurrencyInput
                  autoFocus={true}
                  className="form-control"
                  name="authAmount"
                  placeholder={formattedAuthAmount}
                  value={values.authAmount}
                  onBlur={(_, { numericValue, formattedValue }) => {
                    setAuthAmount(numericValue);
                    setFieldValue('authAmount', formattedValue);
                  }}
                  onChange={handleChange}
                />
                {errors.authAmount && <small className="text-danger">{errors.authAmount}</small>}
              </Form.Group>

              <ThemedButton
                block
                type="submit"
                disabled={!isValid}
              >
                Continue
              </ThemedButton>

            </FormikForm>
          </Card.Body>
        </Card>
      }

    </Formik>
  );

  const renderPaymentControl = () => {
    const paymentControl = (
      <PaymentControl
        paymentType={paymentType}
        total={total}
        onPay={handlePay}
      />
    );

    if (paymentType !== 'Card') {
      return (
        <div className="mobile-container">
          {paymentControl}
        </div>
      );
    }

    return (
      <Card className="cc-container shadow-sm">
        <Card.Body>
          {paymentControl}
        </Card.Body>
      </Card>
    );
  };

  const togglePreset = (index) => {
    setTip({
      index,
      amount: tips[index].amount,
      input: getCurrency(tips[index].amount).formattedValue,
    });
  };

  return (
    <Modal
      centered={true}
      show={show}
      className="border-primary"
      size="lg"
      onHide={onCancel}
    >
      <Modal.Body className="tip-container" style={styles.body}>
        <div className="tip-container__title">
          <h2>
            {!editPay && !showPaymentControl && 
              <>
                Pay {` ${formattedAuthAmount} `}
                <EditPay onClick={() => setEditPay(true)}>
                  Edit
                </EditPay>
              </>
            }
            {showPaymentControl && `Total ${Numeral(total).format('$0,0.00')}`}
          </h2>
          <button type="button" className="close" aria-label="Close" onClick={onCancel}>
            <span aria-hidden="true">&times;</span>
          </button>
        </div>

        {editPay && renderAuthAmount()}

        {!editPay && !showPaymentControl &&
          <>
            <h4 className="tip__title">Tip</h4>
            <Row noGutters>
              {tips.map((t, index) =>
                <Col
                  xs={3}
                  key={index}>
                    <TipContainer
                      className="tip"
                      isselected={tip.index === index}
                      onClick={() => togglePreset(index)}
                    >
                        <div className="tip__percent">{t.name}</div>
                        <div className="tip__value">
                          {t.amount > 0 && Numeral(t.amount).format('$0,0.00')}
                          {!t.amount && <i className="fa fa-ban" />}
                        </div>
                    </TipContainer>
                </Col>
              )}

              <Col xs={12}>
                <TipContainer className="tip" isselected={tip.index < 0}>
                  <div className="tip-slider">

                    <h5 className="tip-custom__title">Custom Tip</h5>
                    <CurrencyInput
                      className="form-control"
                      name="tip"
                      style={{ textAlign: 'center' }}
                      value={tip.input}
                      onClick={() => setTip({ ...tip, index: -1 })}
                      onBlur={(_, { numericValue: amount, formattedValue }) => setTip({
                        ...tip,
                        amount,
                        input: formattedValue,
                      })}
                      onChange={e => setTip({
                        ...tip,
                        index: -1,
                        amount: getCurrency(e.target.value).numericValue,
                        input: e.target.value,
                      })}
                    />

                  </div>
                </TipContainer>
              </Col>
            </Row>

            <div className="tip__confirm">
              <ThemedButton
                block
                onClick={() => handleTipConfirm()}
              >
                Continue {` ${getCurrency(total).formattedValue}`}
              </ThemedButton>
            </div>
          </>
        }

        {showPaymentControl && renderPaymentControl()}

        {!qrMode &&
          <BottomButtonGroup
            buttons={buttons}
            style={{
              position: 'absolute',
              left: 0,
              bottom: 0,
              width: '100%',
            }}
          />
        }
      </Modal.Body>
    </Modal>
  );
};

TipPrompt.propTypes = {
  checkAmount: PropTypes.number.isRequired,
  show: PropTypes.bool.isRequired,
  paymentType: PropTypes.string,
  qrMode: PropTypes.bool.isRequired,
  onCancel: PropTypes.func.isRequired,
  onConfirm: PropTypes.func.isRequired,
};

export default withSettings(TipPrompt);