import React, {useContext, useEffect} from 'react';
import pkg from '../../package.json';
import PaymentMethods from './PaymentMethods';
import qs from 'query-string';
import PaymentModal from './PaymentModal';
import { Redirect } from 'react-router-dom';
import { Col, Row } from 'react-bootstrap';
import TipPrompt from './TipPrompt';
import { compose } from 'recompose';
import * as PosApi from '../common/PosApi';
import * as fcc from '../common/fcc';
import { withCheck, withMetadata, withSettings, withToast } from '../common/providers';
import {
  PAYMENT_TYPE_CC,
  PAYMENT_TYPE_DISCOUNT,
  ORDER_STATUS_CANCELLED,
  ORDER_STATUS_ERROR,
  TEXT_CANCEL,
  ORDER_STATUS_TIMEOUT
} from '../common/Constants';
import { RewardPrompt } from './rewards';
import { BottomButtonGroup, ConfirmCancelModal, Container, ItemizedCard, Loading, PaymentListCard, TopNavBar } from '../common/components';
import {TimeoutContext} from '../common/providers/TimeoutProvider';
import {postTransactionWithTimeout} from '../common/PosApi';

const Pay = ({
  check,
  history,
  settings,
  toast,
  tenderTypes,
  terminal,
  location,
  updateCheck,
}) => {
  const [paymentType, setPaymentType] = React.useState();
  const [showCancel, setShowCancel] = React.useState(false);
  const [showRewardPrompt, setShowRewardPrompt] = React.useState(false);
  const [showTip, setShowTip] = React.useState(false);
  const [working, setWorking] = React.useState({ text: 'Updating Order', icon: 'list', show: !check.sent });
  const [transCount, setTransCount] = React.useState(check.payments.length);
  const [inquiryTenderTypeId, setInquiryTenderTypeId] = React.useState();
  const [checkUpdated, setCheckUpdated] = React.useState(false);
  const discountTenderTypeId = tenderTypes.supported[PAYMENT_TYPE_DISCOUNT];
  const ccTenderTypeId = tenderTypes.supported[PAYMENT_TYPE_CC];

  // Change when toggling between kiosk and qr
  const qrMode = true;

  // Determines how card scans will be handled
  const emvMode = terminal._data.TerminalSettings.find(ts => ts.Name === 'pt-emv-mode');
  const routeCreditCardPaymentThroughApi = emvMode && emvMode.Value === 'pebble-api';

  const params = new URLSearchParams(location.search);
  const redirectTo = params.get('redirectTo');
  
  const { 
    setTimeoutMilliseconds, 
    resetTimeoutDurationToDefault, 
    setTimeoutCleanup,
    pauseTimeoutTimer,
    resumeTimeoutTimer,
    resetTimeoutTimer,
  } = useContext(TimeoutContext);

  // Handle idle timeout
  useEffect(() => {
    setTimeoutMilliseconds(settings.payTimeout || 180000);
    const voidOrder = async () => {
      try {
        await PosApi.voidOrder(check.id);
      } catch (err) {
        console.error(err);
      }
    };
    
    setTimeoutCleanup(() => voidOrder);
    
    return () => {
      resetTimeoutDurationToDefault();
      setTimeoutCleanup(null);
    };
  }, []);

  const handleCancel = async (status = ORDER_STATUS_CANCELLED) => {
    setShowCancel(false);

    setWorking({
      ...working,
      text: 'Cancelling order',
      show: true,
    });

    try {
      await PosApi.voidOrder(check.id);
    } catch (err) {
      console.error(err);
    }

    redirectToThankYou({ status });
  };

  const handleContinue = () => {
    if (check.balance) {
      setShowTip(true);
    } else {
      redirectToThankYou({ orderNumber: check.number });
    }
  };

  const handleCreditCardPayment = async ({ amount, tip, payment }) => {
    try {
      // Don't let the idle timer interrupt the payment process
      resetTimeoutTimer();
      pauseTimeoutTimer();
      
      setShowTip(false);

      const { sessionKey, paymentKey, cardNumber, issuer } = payment || {};

      const loadingText = routeCreditCardPaymentThroughApi ? 'Waiting for Card Scan' : 'Applying Payment';

      setWorking({
        ...working,
        icon: 'credit-card',
        text: loadingText,
        show: true,
      });

      
      // Send the partial amount as total
      const curOrder = {
        ...check,
        total: amount,
      };
      

      try {
        const request = {
          attempt: 1,
          paymentKey,
          sessionKey,
          tip,
          order: curOrder,
          version: pkg.version,
          userId: terminal._data.DefaultUserId,
        };

        // When posting to api, this means the api is responsible for issuing the authorize request.
        // Otherwise hpc will be used and will handle the authorize request.
        if (routeCreditCardPaymentThroughApi) {
          try {
            await PosApi.postTransaction({
              amount: curOrder.total,
              tip: tip,
              tenderTypeId: ccTenderTypeId,
              check: curOrder,
              extendedData: null,
            });

            setTransCount(transCount + 1);

          } catch (err) {
            console.error(err);

            toast({ error: "An error occurred when trying to process your payment. Please try again." });
          } finally {
            setWorking({
              ...working,
              show: false,
            });
          }
        } else {
          try {
            const response = await fcc.pay(request);

            await postTransactionAndFinalize(response, amount, tip, { cardNumber, issuer });
          } finally {
            setWorking({
              ...working,
              show: false,
            });
          }
        }

      } catch (err) {
        console.error(err);

        setWorking({
          ...working,
          show: false,
        });

        toast({ error: err.message });
        setShowTip(true);
      }
    } finally {
      resumeTimeoutTimer();
    }
  };

  const handleHideCancel = () => {
    setShowCancel(false);
  };

  const handlePaymentMethodSelect = (paymentType) => {
    setPaymentType(paymentType);
    setShowTip(!!paymentType);
  };

  const handlePosting = async (transaction) => {
    setInquiryTenderTypeId();
    setTransCount(transCount + 1);
  };

  const handleShowCancel = () => {
    setShowCancel(true);
  };

  const postTransactionAndFinalize = async (response, amount, tip, cardInfo) => {
    try {
      await PosApi.postCreditCardPayment(check, ccTenderTypeId, response, amount, tip, cardInfo);
      setTransCount(transCount + 1);
    } catch (err) {
      console.error(err);

      toast({ error: "An error occurred when trying to process your payment. Please try again." });
    }
  };

  const redirectToDiscounts = (guestId) => history.push(`/pay/rewards?id=${guestId}`);

  const redirectToThankYou = React.useCallback((query) => {
    const queryStringValue = qs.stringify(query);
    history.push(`/thankyou?${queryStringValue}`);
  }, [history]);

  const handleAddPlayerCard = (swipeData) => {
    const guestId = swipeData.id;
    if (!guestId) {
      toast({ error: 'Bad swipe, try again.' });
      return;
    }

    redirectToDiscounts(guestId);
  };

  const finalizeOrder = React.useCallback(async (updatedCheck) => {
    try {
      setWorking(value => ({
        ...value,
        icon: 'th',
        text: 'Finalizing Order',
        show: true,
      }));

      await PosApi.finalizeOrder(updatedCheck);

      redirectToThankYou({ orderNumber: updatedCheck.number });
    } catch (err) {
      console.error(err);

      setWorking(value => ({
        ...value,
        show: false,
      }));

      toast({ error: err.message });
      redirectToThankYou({ status: ORDER_STATUS_ERROR });
    }
  }, [redirectToThankYou, toast, setWorking]);

  React.useEffect(() => {
    const finalize = async () => await finalizeOrder(check);

    // Finalize order if balance is paid
    if (!check.balance) {
      finalize();
    }
  }, [check, finalizeOrder]);

  React.useEffect(() => {
    const fetchOrder = async () => {
      try {
        const apiOrder = await PosApi.getOrder(check.id);
        updateCheck(apiOrder);
        setCheckUpdated(true);
      } catch (err) {
        console.error(err);
        toast({ error: err.message || 'Error occurred while getting your updated order. Try again.' });
      }
    };
    fetchOrder();
  }, [check.id, updateCheck, toast, transCount, finalizeOrder]);

  const getCustomerAccountText = () => {
    if (!check.customerAccounts) {
      return '';
    }
    
    const accountKey = Object.keys(check.customerAccounts)[0];
    const account = check.customerAccounts[accountKey];
    
    let accountText = '';

    if (account.cardNumber) {
      // Mask all but the last four digits of the card number
      const maskedCardNumber = account.cardNumber
        .slice(-4)
        .padStart(account.cardNumber.length, 'X');
      
      accountText = maskedCardNumber + ' ';
    }
    
    accountText += account.displayName 
      ? account.displayName 
      : account.firstName + ' ' + account.lastName;
    
    return accountText;
  };

  const topButtons = [];
  
  if (terminal._data.AccountInquiryDefaultTenderTypeId && !check.customerAccounts) {
    topButtons.push({
      name: 'Add Loyalty Card',
      icon: 'id-card',
      disabled: !check.balance,
      primary: true,
      onClick: () => history.push('/playerswipe'),
    });
  }

  const bottomButtons = [
    {
      name: 'Cancel',
      onClick: handleShowCancel,
    },
  ];

  if (!qrMode) {
    bottomButtons.push({
      name: 'Pay',
      primary: true,
      disabled: !ccTenderTypeId,
      onClick: handleContinue,
    });
  }

  if (redirectTo) {
    return <Redirect to={redirectTo} />;
  }

  return (
    <Container style={{ paddingBottom: 100 }}>
      <TopNavBar title="Pay" buttons={topButtons} text={getCustomerAccountText()} />

      {!qrMode &&
        <RewardPrompt
          show={showRewardPrompt}
          onSwipe={handleAddPlayerCard}
          onCancel={() => setShowRewardPrompt(false)}
        />
      }

      <Loading {...working} />

      {check.sent && (
        <>
          <Row className="my-3">
            <Col lg={8}>
              <div className="mb-3">
                {checkUpdated && // Doesn't let ItemizedCard show until check has finished switching formats
                  <ItemizedCard items={check.items} />
                }
              </div>
            </Col>
            <Col lg={4}>
              <PaymentListCard check={check} />
            </Col>
          </Row>

          {qrMode &&
            <>
              <h6 className="text-uppercase">Payment Method</h6>
              <PaymentMethods
                tenderTypes={tenderTypes}
                onSelect={handlePaymentMethodSelect}
                onInquirySelect={tenderTypeId => setInquiryTenderTypeId(tenderTypeId)}
              />
            </>
          }
        </>
      )}

      {inquiryTenderTypeId &&
        <PaymentModal
          show={!!inquiryTenderTypeId}
          check={check}
          tenderType={tenderTypes[inquiryTenderTypeId]}
          onConfirm={handlePosting}
          onCancel={() => setInquiryTenderTypeId()}
        />
      }

      {showTip &&
        <TipPrompt
          checkAmount={check.balance}
          show={showTip}
          qrMode={qrMode}
          paymentType={paymentType}
          onCancel={() => setShowTip(false)}
          onConfirm={handleCreditCardPayment}
          shouldRenderPaymentControl={!routeCreditCardPaymentThroughApi}
        />
      }

      <ConfirmCancelModal
        message={TEXT_CANCEL}
        show={showCancel}
        onCancel={handleHideCancel}
        onConfirm={handleCancel}
      />

      <BottomButtonGroup buttons={bottomButtons} />

    </Container>
  );
};

export default compose(
  withCheck,
  withMetadata,
  withSettings,
  withToast,
)(Pay);